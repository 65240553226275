/* ----------------
  General
---------------- */
::selection {
  background: #000;
  color: $yellow;
  opacity: 1;
}

a {
  text-decoration: underline;
  color: $link_color;

  &:hover {
    text-decoration: underline;
  }
}

em,
i {
  font-style: italic;
}

strong,
b {
  font-weight: $weight_bold;
}

.invert-text {
  background-color: #000;
  color: #fff;
  padding: 0 0.5rem;
}

/* ----------------
  Headers
---------------- */
h1, h2, h3, h4, h5 {
  font-family: $font_mono;
  text-transform: uppercase;
  font-weight: $weight_super;
  word-spacing: -0.4em;

  &.light {
    font-weight: normal;
  }
}

h1,.h1 {
  font-size: px-rem(40);
  line-height: px-rem(48);
  font-weight: $weight_super;
  text-transform: uppercase;
  margin: 0 0 px-rem(24);

  @include mq($landscape) {
    font-size: px-rem(64);
    line-height: px-rem(72);
    margin: 0 0 px-rem(32);
  }
  @include mq($desktop) {
    font-size: px-rem(80);
    line-height: px-rem(88);
    margin: 0 0 px-rem(32);
  }
}

h2,.h2 {
  font-size: px-rem(40);
  line-height: px-rem(48);
  font-weight: $weight_super;
  text-transform: uppercase;
  margin: 0 0 px-rem(24);

  h1 + & {
    margin-top: px-rem(-16);
    margin-bottom: px-rem(32);
  }

  @include mq($portrait) {
    font-size: px-rem(40);
    line-height: px-rem(48);
    margin: 0 0 px-rem(32);

    h1 + & {
      margin-top: px-rem(-8);
      margin-bottom: px-rem(48);
    }
  }
  @include mq($landscape) {
    font-size: px-rem(40);
    line-height: px-rem(48);
    margin: 0 0 px-rem(32);

    h1 + & {
      margin-top: px-rem(-8);
      margin-bottom: px-rem(40);
    }
  }
  @include mq($desktop) {
    font-size: px-rem(48);
    line-height: px-rem(56);
    margin: 0 0 px-rem(32);

    h1 + & {
      margin-top: px-rem(-8);
      margin-bottom: px-rem(48);
    }
  }
}

h3,.h3 {
  font-size: px-rem(32);
  line-height: px-rem(40);
  font-weight: $weight_super;
  text-transform: uppercase;
  margin: 0 0 px-rem(32);

  &.light,.light {
    font-weight: normal;
  }
}

h4 {
  font-size: px-rem(32);
  line-height: px-rem(40);
  font-weight: $weight_super;
  text-transform: uppercase;
}

h5,.h5 {
  font-size: px-rem(20);
  line-height: px-rem(24);
  font-weight: $weight_bold;
  margin:  0 0 px-rem(16);
}

/* ----------------
  Content
---------------- */
.lead {
  font-family: $font_mono;
  text-transform: uppercase;
  font-weight: $weight_super;
  font-size: px-rem(16);
  word-spacing: -0.2em;
  line-height: px-rem(24);
  max-width: grid_px(7);

  @include mq($landscape) {
    font-size: px-rem(18);
    line-height: px-rem(28);
  }
  @include mq($desktop) {
    font-size: px-rem(20);
    line-height: px-rem(32);
  }

  &.light {
    font-weight: $weight_bold;
  }

  strong {
    font-weight: $weight_super;
  }

  &--divider {
    &:after {
      content: '';
      display: block;
      height: 12px;
      margin-top: 2rem;
      margin-bottom: 3rem;
      background: transparent url('../img/dots-pattern.svg') repeat left top;
    }
  }
}

p {
  margin: 0 0 2rem;

  &.small {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

blockquote {
  font-size: px-rem(48);
  line-height: px-rem(56);

  p {
    max-width: none;
    padding: 0;
  }
}
