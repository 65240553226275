.site-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 3.5rem 0 8rem;

  a {
    margin: 0 0.75rem;
    display: block;

    img {
      display: block;
    }
  }

  @include mq($portraitPlus) {
    padding: px-rem(80) 0;
  }
}
