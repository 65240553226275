/* Wrapper */
.section--generator {
  position: relative;
  z-index: -2;
  background: transparent url('../img/dots-generator.svg') top center;
  background-size: auto 50%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 8vh 0 calc(8vh + 4px);
  margin: 0;

  .container {
    width: 100%;
  }

  @include mq($portraitPlus) {
    margin-top: -1rem;
  }

  @include mq($portrait) {
    min-height: 0px;
    padding: 10vh 0;
    min-height: calc(80vh - 168px);
  }
  @include mq($landscape) {
    margin-top: -2rem;
    min-height: calc(80vh - 168px);
  }
  @include mq($desktop) {
    margin-top: -1rem;
    min-height: calc(80vh - 168px);
  }
}

/* Input */
.generator {
  display: none;
}

/* Output */
.generated {
  @include shadow($width: 3px, $shadow2: false, $size: 0.5rem);
  background-color: #fff;
  padding: 8.5vw 8vw calc(8.5vw - 0.75rem);
  //padding: 8vh 0;

  &:after {
    background-size: 4rem auto;
  }

  h1 {
    font-size: px-rem(24);
    line-height: 1.1;
    margin-bottom: 2rem;
  }

  h2 {
    margin: 0;

    @include mq($mobile) {
      font-size: px-rem(20);
      line-height: px-rem(28);
    }
  }

  @include mq($portraitPlus) {
    &:before {
      top: 1rem;
      left: 1rem;
      right: -1rem;
      bottom: -1rem;
    }
  }

  @include mq($portrait) {
    margin-right: px-rem(48);
    padding: px-rem(56) px-rem(48) px-rem(48);
    box-shadow: inset 0px 0px 0px 4px #000;

    &:before {
      box-shadow: inset 0px 0px 0px 4px #000;
    }

    h1 {
      font-size: px-rem(48);
      line-height: 1.1;
    }

    h2 {
      font-size: px-rem(32);
      line-height: px-rem(40);
    }
  }

  @include mq($landscape) {
    margin-right: px-rem(96);
    padding: px-rem(56) px-rem(48) px-rem(48);
    box-shadow: inset 0px 0px 0px 4px #000;

    &:before {
      box-shadow: inset 0px 0px 0px 4px #000;
    }

    h1 {
      font-size: px-rem(56);
      line-height: 1.1;
    }

    h2 {
      font-size: px-rem(40);
      line-height: px-rem(48);
    }
  }
  @include mq($desktop) {
    max-width: grid_px(10.5);
    box-shadow: inset 0px 0px 0px 6px #000;
    padding: px-rem(80) px-rem(72) px-rem(72);

    &:before {
      box-shadow: inset 0px 0px 0px 6px #000;
    }

    h1 {
      font-size: px-rem(72);
      line-height: 1.1;
    }
  }
}

.generated__value {
  position: relative;
  display: inline-block;
  animation: optionIn 0.2s ease;
  animation-fill-mode: forwards;

  /*&:not(&--fact):after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0.5rem;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: #000;
  }*/

  &.animate {
    animation: optionOut 0.3s ease;
    animation-fill-mode: forwards;
  }

  &--fact {
    display: none;
    font-family: $font_mono;
    font-weight: $weight_super;
    font-size: px-rem(14);
    line-height: px-rem(20);
    text-transform: uppercase;

    p {
      margin-bottom: px-rem(20);
    }
  }
}


/* Animations */
@keyframes optionIn {
  from {
    transform: translateY(1rem);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes optionOut {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@keyframes bgMove {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 0% -100%;
  }
}
