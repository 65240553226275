/* Remove all defaults and set default size */
@include forminputs {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: 0px;
  border: none;
  height: 3.5rem;
  padding: 0 1rem;
  font-family: $font_normal;
  font-size: 14px;
  display: inline-block;
}

@include forminputs {
  background-color: #fff;
  border: 2px solid $border_color;
  box-shadow: 0px 0px 0px 0px rgba($primary, 0);
  transition: all 0.2s ease, width 0s, height 0s;

  &::placeholder {
    color: $textgray;
  }

  &:hover {
    border-color: $primary;
  }

  &:focus,
  &:active {
    outline: none;
    border-color: #000;
    box-shadow: 0px 0px 0px 4px rgba($primary, 0.5);
    border-radius: 0.5px;
  }
}

select {
  @include icon-stroke($icon: $selectarrow, $padding: false, $background-position: right px-rem(20) center);
  padding-right: 3.25rem;
}
select::-ms-expand {
  display: none; /* hide the default arrow in ie10 and ie11 */
}
/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
  select {
    background-image:none\9;
    padding: 5px\9;
  }
}

textarea {
  padding: 1rem;
  line-height: 1.4;
  height: auto;
}

input[type="checkbox"], input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  background: #fff;
  display: inline-block;
  background: none;
  border: none;
  box-shadow: none;
  margin: 0;
  vertical-align: middle;

  &:before {
    content: "";
    background-image: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    outline: none;

    background-color: #fff;
    border: 2px solid $border_color;
    box-shadow: 0px 0px 0px 0px rgba($primary, 0);
    transition: all 0.2s ease, width 0s, height 0s;
    background-position: center center;
  }

  &:hover:before {
    border-color: $primary;
  }

  &:focus:before,
  &:active:before {
    outline: none;
    border-color: #000;
    box-shadow: 0px 0px 0px 4px rgba($primary, 0.5);
  }
}

input[type="checkbox"] {
  &:checked:before {
    @include icon-stroke($icon: $tick, $background-position: center center, $padding: false);
  }

  &:focus:before {
    border-radius: 0.5px;
  }
}

input[type="radio"] {
  &:before {
    border-radius: 50%;
  }

  &:checked:before {
    @include icon-fill($icon: $radio_circle, $background-position: center center, $padding: false);
  }
}

input[type="submit"],
button {
  appearance: none;
  border: none;
  box-shadow: none;
  @include btn();
  left: auto;
  padding-top: 0;
}

label {
  display: block;
  color: #000;
  margin-bottom: 1rem;

  //Add break after label without forcing block on label or input
  &:before,
  &:after {
    content: '';
    display: block;
    height: 0rem;
  }

  //Inline if class or checkbox/radio (after input)
  input[type="checkbox"] + &,
  input[type="radio"] + &,
  &.inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    padding-left: 0.5rem;
    margin-bottom: 0;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.5rem;

    &:after,
    &:before {
      display: none;
    }
  }

  input[type="checkbox"] + &,
  input[type="radio"] + & {
    font-weight: normal;
  }
}


/* Gravity form */
.gform_fields {
  margin: 0 -1rem 0 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;

  @include mq($landscape) {
    margin-right: -1.5rem;
  }
  @include mq($desktop) {
    margin-right: -2rem;
  }

  li {
    list-style: none;
    list-style-image: none;
    margin: 0 0 1rem 0;
    padding: 0 1rem 0 0;
    width: 100%;

    @include mq($portraitPlus) {
      &.half {
        width: 50%;
      }
    }

    @include mq($landscape) {
      margin: 0 0 1.5rem 0;
      padding: 0 1.5rem 0 0;
    }

    @include mq($desktop) {
      margin: 0 0 2rem 0;
      padding: 0 2rem 0 0;
    }

    @include forminputs {
      display: block;
      width: 100%;
    }
  }
}
