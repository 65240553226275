/* Options */
$max-width: 1208;
$grid_cols: 12;
$grid_width: 1208;
$grid_gutter: 40;
$grid_col: 64;

/* Colors */
$yellow: #FFEC2B;

//Gray colors (dark - light) - Based on https://davidwalsh.name/sass-color-variables-dont-suck
$textgray: #575762;

/* Set default color vars */
$primary: $yellow;
$secondary: #ff88aa;
$comp: #f7bc8c;

$theme: $primary;

$text_color: #000;
$text_color_light: #ffffff;
$title_color: #000;
$link_color: #000;
$border_color: #000;

/* Text styles */
$font-size: px-rem(20);
$line-height: px-rem(32);

$weight_light: 300;
$weight_normal: normal;
$weight_semi: 600;
$weight_bold: bold;
$weight_super: 900;

$weight_default: $weight_normal;

/* Fonts */
$font_mono: 'alma_mono', monospace;
$font_normal: 'helvetica neue', 'helvetica', arial, sans-serif;

/* Misc */
$easing_back: cubic-bezier(0.175, 0.885, 0.320, 1.275);

/* Responsive break points */
$mobile: "screen and (max-width: 767px)";
$portraitPlus: "screen and (min-width: 768px)";
$portrait: "screen and (min-width: 768px) and (max-width: 1023px)";//iPad inportrait mode +
$landscape: "screen and (min-width: 1024px)  and (max-width: 1151px)";//iPad in landscape mode - MacBook Air
$landscapePlus: "screen and (min-width: 1024px)";//iPad +
$desktop: "screen and (min-width: 1152px)";//MacBook Air +
$desktop_large: "screen and (min-width: 1440px)";//Over 15" Macbook
