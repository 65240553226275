html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

img,
video {
  max-width: 100%;
  height: auto;
}

body {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  background-color: $yellow;
  font-family: $font_normal;
  font-size: px-rem(16);
  line-height: px-rem(24);
  color: #000;

  @include mq($landscape) {
    font-size: px-rem(18);
    line-height: px-rem(28);
  }
  @include mq($desktop) {
    font-size: px-rem(20);
    line-height: px-rem(32);
  }
}

.container {
  @include contain();
}

/* Smoothen image files just a bit */
.smooth {
  border-radius: 1px;
}

/* Lazy load images styles */
img:not([src]) {
	visibility: hidden;
}
/* Fixes Firefox anomaly during image load */
@-moz-document url-prefix() {
	img:-moz-loading {
		visibility: hidden;
	}
}

.lazyload {
  image-rendering: pixelated;
}

.lazyloaded {
  image-rendering: auto;
}
