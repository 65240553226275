/* ***
  NAVIGATION
*** */

.menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  a:not(.btn) {
    display: block;
    color: #000;
    text-decoration: none;
    padding-top: px-rem(8);
    padding-bottom: px-rem(4);
    transition: all 0.3s ease;
  }

  &__item {
    position: relative;
    font-family: $font_mono;
    font-size: px-rem(14);
    line-height: px-rem(20);
    font-weight: $weight_bold;
    text-transform: uppercase;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -0.5rem;
      bottom: px-rem(0);
      width: 0px;
      height: 100%;
      background-color: #000;
      transition: all 0.2s ease;
      pointer-events: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
    }

    &--active {
      &:after {
        content: '';
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 2px;
        background-color: #000;
      }
    }

    @include mq($portraitPlus) {
      margin-right: 1.5rem;
    }
  }
}
