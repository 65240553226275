/* ---- Header ---- */
.site-header {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-top: px-rem(24);
  margin-bottom: px-rem(24);

  @include mq($portrait) {
    margin-top: px-rem(40);
    margin-bottom: px-rem(56);
  }
  @include mq($landscapePlus) {
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: px-rem(56);
    margin-bottom: px-rem(80);
  }
}

.logo {
  &, svg {
    display: block;
  }

  &:hover {
    .svg-logo__inner {
      * {
        @media (hover: hover) {
          animation: wave infinite 0.7s ease;
        }
      }
    }
  }
}

.logo .svg-logo {
  &__inner {
    @for $i from 1 to 8 {
      *:nth-child(#{$i}) {
        animation-delay: ($i * 0.03s - 0.03);
      }
    }
  }
}

.site-header__nav {
  .menu li:first-child {
    @include mq($portraitPlus) {
      display: none;
    }
  }
}

.menu a,
.btn {
  .letter {
    display: inline-block;

    &--space {
      margin-right: 0.6em;
    }
  }

  &:hover {
    span {
      @media (hover: hover) {
        animation: wave 0.35s ease;
      }
    }
  }

  span {
    @for $i from 1 through 100 {
      &:nth-child(#{$i}) {
        animation-delay: ($i * 0.03s - 0.03);
      }
    }
  }

  &.item--long {
    a {
      animation-duration: 0.25s;
    }

    span {
      @for $i from 1 through 100 {
        &:nth-child(#{$i}) {
          animation-delay: ($i * 0.02s - 0.02);
        }
      }
    }
  }
}

.nav-toggle {
  @include mq($portraitPlus) {
    display: none;
  }
}

@keyframes wave {
  0%,100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
}

@keyframes wavebig {
  0%,50%,100% {
    transform: translate3d(0,0px,0);
  }
  25% {
    transform: translate3d(0,-8px,0);
  }
  75% {
    transform: translate3d(0,8px,0);
  }
}


/* Mobile menu */
@include mq($mobile) {
  .site-header__nav {
    position: fixed;
    z-index: 350;
    left: 1rem;
    right: 1rem;
    bottom: 7rem;
    @include shadow($shadow1: 'lines', $position: false, $size: 0.5rem, $background: #fff);
    transition: all 0.4s $easing_back, opacity 0.3s ease;

    pointer-events: none;
    opacity: 0;
    transform: translateY(3rem);

    ul {
      padding: 1.5rem 2rem 2.25rem;
      background-color: $yellow;
      border: 2px solid #000;

      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;

      li {
        margin-bottom: 1rem;

        a:not(.btn) {
          padding: 0.75rem 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .menu__item--active {
        margin-bottom: 1.5rem;
      }
    }
  }

  .nav-toggle {
    position: fixed;
    z-index: 400;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3.5rem;
    height: 3.5rem;
    background-color: #000;
    border-radius: 50%;
    border: 2px solid $yellow;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 1rem;
      right: 1rem;
      height: 2px;
      background-color: #fff;
      transform: translateY(-50%);
      transition: all 0.2s 0s ease, top 0.2s 0.2s ease;
    }
    &:before {
      top: calc(50% - 4px);
    }
    &:after {
      top: calc(50% + 4px);
    }
  }

  .nav-open {
    .site-header__nav {
      pointer-events: auto;
      opacity: 1;
      transform: translateY(0rem);
    }

    .nav-toggle {
      &:before,
      &:after {
        top: 50%;
        transition-delay: 0.2s, 0s;
      }
      &:before {
        transform: translateY(-50%) rotate(-45deg);
      }
      &:after {
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
}
