.section {
  @include contain();
  margin-top: px-rem(64);

  *:not(.grid):last-child {
    margin-bottom: 0;
  }

  @include mq($portrait) {
    margin-top: px-rem(80);
  }
  @include mq($landscape) {
    margin-top: px-rem(88);
  }
  @include mq($desktop) {
    margin-top: px-rem(96);
  }
}

.section--wide {
  max-width: 1440px;
}

.section--full {
  max-width: none;
}

.section--center {
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.section--center-self {
  margin-left: auto;
  margin-right: auto;
}

.margin {
  &--large {
    margin-top: px-rem(72);

    @include mq($portrait) {
      margin-top: px-rem(96);
    }
    @include mq($landscape) {
      margin-top: px-rem(112);
    }
    @include mq($desktop) {
      margin-top: px-rem(128);
    }
  }
  &--small {
    margin-top: px-rem(48);

    @include mq($portrait) {
      margin-top: px-rem(56);
    }
    @include mq($landscape) {
      margin-top: px-rem(64);
    }
    @include mq($desktop) {
      margin-top: px-rem(72);
    }
  }
  &--smaller {
    margin-top: px-rem(32);

    @include mq($portrait) {
      margin-top: px-rem(40);
    }
    @include mq($landscape) {
      margin-top: px-rem(48);
    }
    @include mq($desktop) {
      margin-top: px-rem(64);
    }
  }
  &--none {
    margin-top: 0;
  }
}

.padding--none {
  padding: 0;
}


/* GENERAL SECTIONS (Partials) */
.page__end {
  margin: 7rem auto;

  p:not(.small) {
    max-width: grid_px(8);
    margin-left: auto;
    margin-right: auto;
  }
  p.small {
    max-width: grid_px(7);
    margin-left: auto;
    margin-right: auto;
  }
}
