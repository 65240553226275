.about {

}

.about__header {
  h2 {
    font-size: px-rem(28);
    line-height: px-rem(36);

    @include mq($landscape) {
      font-size: px-rem(40);
      line-height: px-rem(48);
    }
    @include mq($desktop) {
      font-size: px-rem(44);
      line-height: px-rem(52);
    }
  }

  .lead {
    margin-bottom: 0;
  }
}

.about__contactstart {
  &__image {
    @include detail($pattern: 'lines', $x: false, $y: false, $width: 10, $height: 10);
    @include detail($target: 'before', $x: 'left', $y: 'bottom', $width: 11, $height: 11);
    margin-bottom: px-rem(56);

    img {
      display: block;
    }

    &:after {
      right: -3rem;
      top: -3rem;
    }
  }
}

.about__contactlist {
  .grid {
    grid-row-gap: 3rem;
  }
  .grid__item {
    width: 100%;
    max-width: 330px;
    text-align: center;
    justify-self: center;

    h5 {
      margin-bottom: 0.5rem;
    }
  }

  @include mq($portraitPlus) {
    .grid {
      padding: 0 1.5rem;
      grid-row-gap: 4rem;
    }

    .grid__item {
      justify-self: start;
      text-align: left;

      h5 {
        margin-bottom: 1rem;
      }

      &:nth-child(3n - 1) {
        justify-self: center;
      }
      &:nth-child(3n+3) {
        justify-self: end;
      }
    }
  }
}
