.frontpage--mh2018 {

}

.frontpage__intro {
  @include mq($portraitPlus) {
    padding-top: 4rem;
  }

  .grid {
    position: relative;
  }

  .grid__item:first-child {
    //position: sticky;
    //top: 3rem;
    //padding-bottom: 3rem;
    z-index: 10;
    align-self: start;
    order: 2;
    padding-top: 1.5rem;

    @include mq($portraitPlus) {
      order: 0;
      padding-top: 0;
    }
  }
  .grid__item:last-child {
    @include shadow($shadow1: 'lines', $size: px-rem(8), $width: 2px);
    margin-top: 0;

    &:before {
      background-position: bottom right;
    }

    video,
    img {
      display: block;
      border: 2px solid #000;
    }

    @include mq($portraitPlus) {
      margin-top: -4rem;

      &:before {
        top: 1.5rem;
        left: 1.5rem;
        right: -1rem;
        bottom: -1rem;
        box-shadow: 0px 0px 0px 4px #000;
        background-size: 48px 48px;
      }

      video,img {
        border-width: 4px;
      }
    }
  }
}

.frontpage__usp {
  position: relative;
  text-align: center;

  .section__inner {
    position: relative;
    box-shadow: inset 0px 0px 0px 2px #000;
    background-color: #fff;
    margin: px-rem(8);
    padding: px-rem(56) px-rem(40) px-rem(64);

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: -8px;
      top: -8px;
      right: -8px;
      bottom: -8px;
      background: transparent url('../img/lines-pattern.svg') repeat center center;
    }
  }

  &__title {
    margin: 0 0 px-rem(48);
    font-weight: $weight_super;
  }

  .grid__item {
    h5 {
      font-size: 18px;
      line-height: 24px;
      font-weight: $weight_super;
      word-spacing: -0.2em;
    }

    img {
      margin-bottom: 1.5rem;
    }
  }

  &__action {
    font-family: $font_mono;
    font-weight: $weight_super;
    font-size: px-rem(18);
    line-height: 1.5rem;
    word-spacing: -0.2em;
    text-transform: uppercase;
    color: $textgray;
    margin: px-rem(56) 0;
  }
}

.grogrid {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: px-rem(8);
}
.grogrid__item {
  &:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: span 3;
  }
  &:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 6;
  }
  &:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  &:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}


@include mq($portraitPlus) {
  .grogrid {
    margin-left: -1rem;
    margin-right: -1rem;

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .grogrid__item {
    margin-bottom: 0;

    &:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: span 2;
    }
    &:nth-child(2) {
      grid-column-start: 4;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 1;
    }
    &:nth-child(3) {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 2;
    }
    &:nth-child(4) {
      grid-column-start: 5;
      grid-column-end: 6;
      grid-row-start: 2;
      grid-row-end: 2;
    }
  }
}


.frontpage__gallery {
  .grid__item {
    @include shadow($size: px-rem(8), $background: #fff);
    border: 2px solid #000;
  }

  /*.grid__item:nth-child(1) {
    @include detail($y: 'top', $width: 11, $height: 5);
  }

  .grid__item:nth-child(2) {
    @include detail($pattern: 'lines', $x: false, $y: false, $width: 10, $height: 10);

    &:after {
      bottom: -1rem;
      left: -1rem;
    }
  }

  .grid__item:nth-child(3) {
    @include detail($y: 'top', $x: 'right', $width: 6, $height: 6);
  }

  .grid__item:nth-child(4) {
    @include detail($y: 'bottom', $x: 'left', $width: 5, $height: 11);
  }

  .grid__item:nth-child(6) {
    @include detail($y: 'bottom', $x: 'right', $width: 7, $height: 9);
  }*/
}

.frontpage__organizers {
  background: #000 url('../img/dots-pattern-org.svg');
  color: #fff;
  padding: px-rem(64) 0;
  text-align: center;

  h2 {
    margin-bottom: px-rem(32);
  }

  .grid {
    max-width: grid_px(10);
    margin: 0 auto;
    grid-row-gap: px-rem(56);
  }

  .grid__item {
    max-width: px-rem(256);
    justify-self: center;
  }

  a {
    font-weight: $weight_bold;
    color: #fff;
  }

  @include mq($portraitPlus) {
    padding: px-rem(96) 0;

    h2 {
      margin-bottom: px-rem(48);
    }
  }

  .organizer {
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: px-rem(72);
      margin-bottom: 1.5rem;

      img {
        display: block;
      }

      @include mq($portraitPlus) {
        margin-bottom: 2rem;
      }
    }
  }
}
